import React from 'react';
import Styles from '../styles.module.scss';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import AttachModalNew from '../../../../common/AttachModalNew';
import attachStyles from '../../../../../styles/react-modal/attach.style';
import * as filesAction from '../../../../../store/actions/files';
import * as ATTACH from '../../../../../constants/attach';

export default ({
    activeTypeFile,
    setActiveTypeFile,
    setAttachModalIsOpen,
    attachFile,
    attachModalIsOpen,
    femaleExternalId,
    isCreateLetterForm,
}) => {
    const dispatch = useDispatch();

    const currentMailboxState = useSelector((state) => state.currentChatMailbox);

    const { info } = currentMailboxState;

    const femaleId = femaleExternalId ? +femaleExternalId : +info?.female_id;

    const openModal = () => {
        setAttachModalIsOpen(true);
        dispatch(filesAction.getAllImages(femaleId));
        dispatch(filesAction.getAllVideos(femaleId));
        dispatch(filesAction.getAllAudios(femaleId));
    };

    const closeModal = () => {
        setAttachModalIsOpen(false);
    };

    return (
        <>
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.image}`}
                onClick={() => {
                    setActiveTypeFile(ATTACH.TAB_IMAGE);
                    openModal();
                }}
                data-testid="add-image-btn"
            />
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.video}`}
                onClick={() => {
                    setActiveTypeFile(ATTACH.TAB_VIDEO);
                    openModal();
                }}
                data-testid="add-video-btn"
            />
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.audio}`}
                onClick={() => {
                    setActiveTypeFile(ATTACH.TAB_AUDIO);
                    openModal();
                }}
                data-testid="add-audio-btn"
            />
            <Modal style={attachStyles} isOpen={attachModalIsOpen} onRequestClose={closeModal} ariaHideApp={false}>
                <AttachModalNew
                    closeModal={closeModal}
                    sendImg={(e) => attachFile(e, 'SENT_IMAGE')}
                    sendVideo={(e) => attachFile(e, 'SENT_VIDEO')}
                    sendAudio={(e) => attachFile(e, 'SENT_AUDIO')}
                    userId={femaleId}
                    activeTypeFile={activeTypeFile}
                    setActiveTypeFile={setActiveTypeFile}
                    isCreateLetterForm={isCreateLetterForm}
                />
            </Modal>
        </>
    );
};
