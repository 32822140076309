import React from 'react';
import Modal from 'react-modal';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import DropZone from 'react-dropzone';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined';
import { Tooltip } from '@mui/material';

import '../../../styles/attach/attach-new.scss';

import UploadImageModal from './UploadImageModal';
import UploadVideoModal from './UploadVideoModal';
import UploadAudioModal from './UploadAudioModal';
import ListVideos from './ListVideos';
import ListImages from './ListImages';
import ListAudios from './ListAudios';

import uploadStyle from '../../../styles/react-modal/upload.style';

import * as ATTACH from '../../../constants/attach';
import * as LENGTH from '../../../constants/length';
import * as IMAGES from '../Images';
import useHandleAttachModal from '../../../hooks/attachModal/useHandleAttachModal';
import useHandleMediaFolder from '../../../hooks/attachModal/useHandleMediaFolder';
import AddToFolderModal from './AddToFolderModal';
import { useSelector } from 'react-redux';

export default ({
    sendImg,
    sendVideo,
    sendAudio,
    closeModal,
    userId,
    activeTypeFile,
    setActiveTypeFile,
    isCreateLetterForm,
}) => {
    const {
        filesState,
        imgFile,
        videoFile,
        audioFile,
        setImgFile,
        setVideoFile,
        setAudioFile,
        modalIsOpenImg,
        modalIsOpenVideo,
        modalIsOpenAudio,
        openModalImg,
        openModalVideo,
        openModalAudio,
        closeModalImg,
        closeModalVideo,
        closeModalAudio,
        handleUploadImg,
        handleUploadVideo,
        handleUploadAudio,
        handleDrop,
        uploadImg,
        uploadVideo,
        uploadAudio,
    } = useHandleAttachModal({ setActiveTypeFile, userId });

    const handleMediaFolder = useHandleMediaFolder({ activeTypeFile, userId });
    const currentChatState = useSelector((state) => state.currentChat);
    const currentChatMailbox = useSelector((state) => state.currentChatMailbox);

    const isVideoContentBlocked =
        !isCreateLetterForm &&
        (currentChatState?.options?.disabledContentConfig?.disabledContent?.videoInChat ||
            currentChatMailbox?.disabledContentConfig?.disabledContent?.videoInLetters);

    const isAudioContentBlocked =
        !isCreateLetterForm &&
        (currentChatState?.options?.disabledContentConfig?.disabledContent?.audioInChat ||
            currentChatMailbox?.disabledContentConfig?.disabledContent?.audioInLetters);

    const isPhotoContentBlocked =
        !isCreateLetterForm &&
        (currentChatState?.options?.disabledContentConfig?.disabledContent?.photoInChat ||
            currentChatMailbox?.disabledContentConfig?.disabledContent?.photoInLetters);

    const folderBtn = () => {
        return (
            <div className="attach_new_popup_folder_btn_wrap">
                {!handleMediaFolder?.activeFolder?.id ? (
                    <div className="attach_new_popup_folder_btn" onClick={handleMediaFolder.onAddToFolder}>
                        <DriveFileMoveOutlinedIcon />
                        <span>Add to folder</span>
                    </div>
                ) : (
                    <>
                        <div className="attach_new_popup_folder_btn" onClick={handleMediaFolder.onAddToFolder}>
                            <DriveFileMoveOutlinedIcon />
                            <span>Move to folder</span>
                        </div>
                        <div
                            className="attach_new_popup_folder_btn delete"
                            onClick={handleMediaFolder.deleteFileFromFolder}
                        >
                            <FolderDeleteOutlinedIcon />
                            <span>Delete from folder</span>
                        </div>
                    </>
                )}
            </div>
        );
    };

    const switchInfoText = (type) => {
        switch (type) {
            case ATTACH.TAB_IMAGE:
                return `Image upload limit <br/>is ${LENGTH.MAX_IMG_SIZE_TITLE} Mb`;
            case ATTACH.TAB_VIDEO:
                return `Video upload limit <br/>is ${LENGTH.MAX_VIDEO_SIZE_TITLE} Mb`;
            case ATTACH.TAB_AUDIO:
                return `Audio upload limit <br/>is ${LENGTH.MAX_AUDIO_SIZE_TITLE} Mb`;
            default:
                return '';
        }
    };

    const sendMediaShowWarning =
        !isCreateLetterForm && (isAudioContentBlocked || isVideoContentBlocked || isPhotoContentBlocked);

    const configTypeIsBlocked = () => {
        const blockedTypes = [];
        if (isPhotoContentBlocked) {
            blockedTypes.push('photo');
        }
        if (isVideoContentBlocked) {
            blockedTypes.push('video');
        }
        if (isAudioContentBlocked) {
            blockedTypes.push('audio');
        }
        return blockedTypes.join(', ');
    };

    const switchListItems = (type) => {
        const activeFolderId = handleMediaFolder.activeFolder?.id;

        const imagesData = !isNaN(activeFolderId) ? filesState?.imageFolders[activeFolderId]?.list : filesState?.images;
        const videosData = !isNaN(activeFolderId) ? filesState?.videoFolders[activeFolderId]?.list : filesState?.videos;
        const audiosData = !isNaN(activeFolderId) ? filesState?.audioFolders[activeFolderId]?.list : filesState?.audios;

        switch (type) {
            case ATTACH.TAB_IMAGE:
                return (
                    <ListImages
                        images={imagesData ?? []}
                        type={ATTACH.IMG_LIST}
                        isSortingDisabled={!isNaN(activeFolderId)}
                    />
                );
            case ATTACH.TAB_VIDEO:
                return (
                    <ListVideos
                        videos={videosData ?? []}
                        type={ATTACH.VIDEO_LIST}
                        isSortingDisabled={!isNaN(activeFolderId)}
                    />
                );
            case ATTACH.TAB_AUDIO:
                return (
                    <ListAudios
                        audios={audiosData ?? []}
                        type={ATTACH.AUDIO_LIST}
                        isSortingDisabled={!isNaN(activeFolderId)}
                    />
                );
            default:
                return '';
        }
    };

    const switchBottomButtons = (type) => {
        switch (type) {
            case ATTACH.TAB_IMAGE:
                return (
                    <>
                        <input
                            type="file"
                            multiple
                            id="popup_inp_file1"
                            data-testid="upload"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                handleUploadImg(e.target.files);
                            }}
                            onClick={(e) => {
                                e.target.value = '';
                                imgFile.size && openModalImg();
                            }}
                        />
                        <label htmlFor="popup_inp_file1" className="attach_new_popup_upload_btn">
                            <img src={IMAGES.attach_upload_ico} alt="" />
                            <span>Upload</span>
                        </label>

                        {folderBtn()}

                        {isPhotoContentBlocked ? (
                            <Tooltip
                                title="At the moment, sending photos for this user is not possible. Please try again later when the user upgrades their status."
                                placement="top"
                            >
                                <div className={`attach_new_popup_send_btn disabled`} data-testid="send-btn">
                                    <img src={IMAGES.attach_send_ico} alt="" />
                                    <span>
                                        Send <span>image</span>
                                    </span>
                                </div>
                            </Tooltip>
                        ) : (
                            <div className="attach_new_popup_send_btn" onClick={sendImg} data-testid="send-btn">
                                <img src={IMAGES.attach_send_ico} alt="" />
                                <span>
                                    Send <span>image</span>
                                </span>
                            </div>
                        )}
                    </>
                );
            case ATTACH.TAB_VIDEO:
                return (
                    <>
                        <input
                            type="file"
                            multiple
                            id="popup_inp_file1"
                            data-testid="upload"
                            accept="video/*"
                            style={{ display: 'none' }}
                            onChange={(e) => handleUploadVideo(e.target.files)}
                            onClick={(e) => {
                                e.target.value = '';
                                videoFile.size && openModalVideo();
                            }}
                        />
                        <label htmlFor="popup_inp_file1" className="attach_new_popup_upload_btn">
                            <img src={IMAGES.attach_upload_ico} alt="" />
                            <span>Upload</span>
                        </label>

                        {folderBtn()}

                        {isVideoContentBlocked ? (
                            <Tooltip
                                title="At the moment, sending videos for this user is not possible. Please try again later when the user upgrades their status."
                                placement="top"
                            >
                                <div className={`attach_new_popup_send_btn disabled`} data-testid="send-btn">
                                    <img src={IMAGES.attach_send_ico} alt="" />
                                    <span>
                                        Send <span>video</span>
                                    </span>
                                </div>
                            </Tooltip>
                        ) : (
                            <div className="attach_new_popup_send_btn" onClick={sendVideo} data-testid="send-btn">
                                <img src={IMAGES.attach_send_ico} alt="" />
                                <span>
                                    Send <span>video</span>
                                </span>
                            </div>
                        )}
                    </>
                );
            case ATTACH.TAB_AUDIO:
                return (
                    <>
                        <input
                            type="file"
                            id="popup_inp_file1"
                            data-testid="upload"
                            accept="audio/*"
                            style={{ display: 'none' }}
                            onChange={(e) => handleUploadAudio(e.target.files)}
                            onClick={(e) => {
                                e.target.value = '';
                                audioFile.size && openModalAudio();
                            }}
                        />
                        <label htmlFor="popup_inp_file1" className="attach_new_popup_upload_btn">
                            <img src={IMAGES.attach_upload_ico} alt="" />
                            <span>Upload</span>
                        </label>

                        {folderBtn()}

                        {isAudioContentBlocked ? (
                            <Tooltip
                                title="At the moment, sending audios for this user is not possible. Please try again later when the user upgrades their status."
                                placement="top"
                            >
                                <div className={`attach_new_popup_send_btn disabled`} data-testid="send-btn">
                                    <img src={IMAGES.attach_send_ico} alt="" />
                                    <span>
                                        Send <span>audio</span>
                                    </span>
                                </div>
                            </Tooltip>
                        ) : (
                            <div className="attach_new_popup_send_btn" onClick={sendAudio} data-testid="send-btn">
                                <img src={IMAGES.attach_send_ico} alt="" />
                                <span>
                                    Send <span>audio</span>
                                </span>
                            </div>
                        )}
                    </>
                );
            default:
                return '';
        }
    };

    const switchModals = (type) => {
        switch (type) {
            case ATTACH.TAB_IMAGE:
                return (
                    <Modal
                        style={uploadStyle}
                        isOpen={modalIsOpenImg}
                        onRequestClose={closeModalImg}
                        ariaHideApp={false}
                    >
                        <UploadImageModal
                            userId={userId}
                            images={filesState?.images}
                            imgFile={imgFile ?? []}
                            setImgFile={setImgFile}
                            handleUploadImg={(e) => handleUploadImg(e.target.files)}
                            closeModalImg={closeModalImg}
                            uploadImg={uploadImg}
                            openModalImg={openModalImg}
                        />
                    </Modal>
                );
            case ATTACH.TAB_VIDEO:
                return (
                    <Modal
                        style={uploadStyle}
                        isOpen={modalIsOpenVideo}
                        onRequestClose={closeModalVideo}
                        ariaHideApp={false}
                    >
                        <UploadVideoModal
                            userId={userId}
                            videos={filesState?.videos}
                            videoFile={videoFile ?? []}
                            setVideoFile={setVideoFile}
                            handleUploadVideo={(e) => handleUploadVideo(e.target.files)}
                            closeModalVideo={closeModalVideo}
                            openModalVideo={openModalVideo}
                            uploadVideo={uploadVideo}
                        />
                    </Modal>
                );
            case ATTACH.TAB_AUDIO:
                return (
                    <Modal
                        style={uploadStyle}
                        isOpen={modalIsOpenAudio}
                        onRequestClose={closeModalAudio}
                        ariaHideApp={false}
                    >
                        <UploadAudioModal
                            userId={userId}
                            audios={filesState?.audios ?? []}
                            audioFile={audioFile}
                            setAudioFile={setAudioFile}
                            handleUploadAudio={(e) => handleUploadAudio(e.target.files)}
                            closeModalAudio={closeModalAudio}
                            uploadAudio={uploadAudio}
                        />
                    </Modal>
                );
            default:
                return '';
        }
    };

    return (
        <>
            <DndProvider backend={Backend}>
                <div className="upload_popup_wrap" data-testid="file-modal">
                    <div className="attach_new_popup_left">
                        <ul className="attach_new_popup_ul" data-testid="tab-block">
                            <li
                                className={`${activeTypeFile === ATTACH.TAB_IMAGE ? 'active' : ''}`}
                                onClick={() => setActiveTypeFile(ATTACH.TAB_IMAGE)}
                                data-testid="photo"
                            >
                                <div className="attach_new_popup_li_wrap">
                                    <div className="attach_new_popup_li_photo" />
                                    <span>{ATTACH.TAB_IMAGE}</span>
                                </div>
                            </li>
                            <li
                                className={`${activeTypeFile === ATTACH.TAB_VIDEO ? 'active' : ''}`}
                                onClick={() => setActiveTypeFile(ATTACH.TAB_VIDEO)}
                                data-testid="video"
                            >
                                <div className="attach_new_popup_li_wrap">
                                    <div className="attach_new_popup_li_video" />
                                    <span>{ATTACH.TAB_VIDEO}</span>
                                </div>
                            </li>
                            <li
                                className={`${activeTypeFile === ATTACH.TAB_AUDIO ? 'active' : ''}`}
                                onClick={() => setActiveTypeFile(ATTACH.TAB_AUDIO)}
                                data-testid="audio"
                            >
                                <div className="attach_new_popup_li_wrap">
                                    <div className="attach_new_popup_li_audio" />
                                    <span>{ATTACH.TAB_AUDIO}</span>
                                </div>
                            </li>
                        </ul>

                        <div className="attach_new_popup_folder">
                            <ul className="attach_new_popup_folder_list">
                                <li className="attach_new_popup_folder_item" onClick={handleMediaFolder.addNewFolder}>
                                    Add new folder
                                    <CreateNewFolderOutlinedIcon style={{ marginLeft: '5px' }} />
                                </li>

                                <li
                                    className={`attach_new_popup_folder_item ${Object.keys(handleMediaFolder.activeFolder).length === 0 ? 'active' : ''}`}
                                    onClick={() => handleMediaFolder.onSetActiveFolder({})}
                                >
                                    All {activeTypeFile}s
                                </li>

                                {!!+handleMediaFolder.folderList?.length &&
                                    handleMediaFolder.folderList.map((folder) => (
                                        <>
                                            {handleMediaFolder?.activeRenameFolder?.id === folder?.id ? (
                                                <input
                                                    className={`attach_new_popup_folder_item ${handleMediaFolder?.activeRenameFolder?.id === folder?.id ? 'active' : ''}`}
                                                    type="text"
                                                    autoFocus
                                                    value={handleMediaFolder?.activeRenameFolder?.name}
                                                    onChange={handleMediaFolder.handleRenameFolder}
                                                    onBlur={() =>
                                                        handleMediaFolder.saveFolderName(
                                                            handleMediaFolder?.activeRenameFolder?.id,
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <Tooltip
                                                    title={folder.id !== 0 && 'Double click to rename'}
                                                    disableInteractive
                                                    key={folder.id}
                                                >
                                                    <li
                                                        className={`attach_new_popup_folder_item ${handleMediaFolder?.activeFolder?.id === folder?.id ? 'active' : ''}`}
                                                        onClick={() => handleMediaFolder.onSetActiveFolder(folder)}
                                                        onDoubleClick={() =>
                                                            handleMediaFolder.onSetActiveRenameFolder(folder)
                                                        }
                                                    >
                                                        {folder.name}
                                                        {!!(
                                                            handleMediaFolder?.activeFolder?.id === folder?.id &&
                                                            folder.id !== 0
                                                        ) && (
                                                            <DeleteOutlineIcon
                                                                className={`attach_new_popup_folder_delete`}
                                                                color="error"
                                                                onClick={handleMediaFolder.openDeleteAlert}
                                                            />
                                                        )}
                                                    </li>
                                                </Tooltip>
                                            )}
                                        </>
                                    ))}
                            </ul>
                        </div>

                        <DropZone onDrop={handleDrop}>
                            {({ getRootProps, isDragActive }) => (
                                <div
                                    {...getRootProps({
                                        className: `attach_new_popup_drag_drop ${isDragActive ? 'accept' : ''}`,
                                    })}
                                >
                                    <div>Drag & drop your files here</div>
                                    <img
                                        src={isDragActive ? IMAGES.attach_file_drop_active : IMAGES.attach_file_drop}
                                        alt=""
                                    />
                                </div>
                            )}
                        </DropZone>
                        <div
                            className="attach_new_popup_info_text"
                            dangerouslySetInnerHTML={{ __html: switchInfoText(activeTypeFile) }}
                        />
                    </div>
                    <div className="attach_new_popup_right">
                        <div className="attach_new_popup_tab_wrap">
                            <div className="attach_new_popup_tab_content">
                                <div className="attach_new_popup_tab_content_top">
                                    <div className="attach_new_popup_tab_content_top_sort">
                                        <img src={IMAGES.move} alt="" />
                                        <span>
                                            Sorting{' '}
                                            {!isNaN(handleMediaFolder.activeFolder?.id)
                                                ? 'in folder is not allowed'
                                                : ''}
                                        </span>
                                    </div>
                                </div>
                                {sendMediaShowWarning && (
                                    <div className="attach_new_popup_tab_top_warning">
                                        {`The user is temporarily unable to receive ${configTypeIsBlocked()} content. Please continue
                                        communicating with the user to lift this restriction.`}
                                    </div>
                                )}
                                <div className="attach_new_popup_tab_content_midddle" id="media-content-block">
                                    {switchListItems(activeTypeFile)}
                                </div>
                                <div className="attach_new_popup_tab_content_bottom">
                                    {switchBottomButtons(activeTypeFile)}
                                </div>
                            </div>
                        </div>
                        {switchModals(activeTypeFile)}
                    </div>
                    <div className="attach_new_popup_close" onClick={closeModal} data-testid="close-btn">
                        <img src={IMAGES.attach_close_ico} alt="" />
                    </div>
                </div>
            </DndProvider>

            <AddToFolderModal {...handleMediaFolder} />
        </>
    );
};
