import React, { useEffect, useState } from 'react';
import MessageForm from './MessageForm';
import { useDispatch, useSelector } from 'react-redux';
import * as mailboxAction from '../../../store/actions/mailbox';
import { userAvatar } from '../../../services/methods';
import Styles from './styles.module.scss';
import * as IMAGES from '../Images';

export default ({ openComposeModal, closeWriteLetterModal, sender }) => {
    const dispatch = useDispatch();

    const mailboxState = useSelector((state) => state.mailbox);
    const profilesState = useSelector((state) => state.profiles);

    const [isActiveTo, setIsActiveTo] = useState(false);

    const onToggleTo = () => setIsActiveTo(!isActiveTo);
    const setSender = (e) => {
        dispatch(
            mailboxAction.setSender(profilesState.list.find((item) => item.external_id === e.currentTarget.dataset.id)),
        );
    };

    useEffect(() => {
        if (profilesState?.active?.id) {
            dispatch(mailboxAction.setSender(profilesState?.active));
        } else {
            dispatch(mailboxAction.setSender(profilesState?.list[0]));
        }
    }, [profilesState.active]);

    return (
        <div>
            <div className="popup_write_letter" data-testid="write-letter-modal">
                <div className="popup_write_letter_head">
                    {sender?.external_id && (
                        <div className="popup_write_letter_fromto">
                            <div className="popup_write_letter_fromto_text">From:</div>
                            {sender ? (
                                <div className="popup_write_letter_user">
                                    <div className="popup_write_letter_user_photo">
                                        <img src={sender?.photo_link} alt="" />
                                    </div>
                                    <div className="popup_write_letter_user_info">
                                        <div className="popup_write_letter_user_name">
                                            {`${sender?.name}, ${sender?.age}`}
                                        </div>
                                        <div className="popup_write_letter_user_bottom">
                                            <div className="popup_write_letter_user_to_id">
                                                {`ID ${sender?.external_id}`}
                                            </div>
                                            <div className="popup_write_letter_user_place">{sender?.country_name}</div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="popup_write_letter_user">
                                    <div className="popup_write_letter_user_photo">
                                        <img src={mailboxState.checkedSender.photo_link} alt="" />
                                    </div>
                                    <div className="popup_write_letter_user_info">
                                        <div className="popup_write_letter_user_name">
                                            {`${mailboxState.checkedSender.name}, ${mailboxState.checkedSender.age}`}
                                        </div>
                                        <div className="popup_write_letter_user_bottom">
                                            <div className="popup_write_letter_user_to_id">
                                                {`ID ${mailboxState.checkedSender.external_id}`}
                                            </div>
                                            <div className="popup_write_letter_user_place">
                                                {mailboxState.checkedSender.country_name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`popup_write_letter_users_hidden`}>
                                        {profilesState.list.map((item) => (
                                            <div
                                                className={`popup_write_letter_user ${+item.online === 1 ? 'online' : ''}`}
                                                key={item.external_id}
                                                data-id={item.external_id}
                                                onClick={setSender}
                                            >
                                                <div className="popup_write_letter_user_photo">
                                                    <img src={item.photo_link} alt="" />
                                                </div>
                                                <div className="popup_write_letter_user_info">
                                                    <div className="popup_write_letter_user_name">{`${item.name}, ${item.age}`}</div>
                                                    <div className="popup_write_letter_user_bottom">
                                                        <div className="popup_write_letter_user_to_id">{`ID ${item.external_id}`}</div>
                                                        <div className="popup_write_letter_user_place">
                                                            {item.country_name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {mailboxState?.checkedRecipients[0]?.external_id && (
                        <div className="popup_write_letter_fromto">
                            <div className="popup_write_letter_fromto_text">To:</div>
                            <div
                                className="popup_write_letter_user online"
                                onClick={mailboxState?.checkedRecipients?.length && onToggleTo}
                            >
                                <div className="popup_write_letter_user_photo">
                                    {userAvatar(
                                        mailboxState?.checkedRecipients[0]?.image,
                                        mailboxState?.checkedRecipients[0]?.name,
                                        '',
                                        Styles.user_avatar,
                                    )}
                                </div>
                                <div className="popup_write_letter_user_info">
                                    <div className="popup_write_letter_user_name">
                                        {`${mailboxState.checkedRecipients[0].name}, ${mailboxState.checkedRecipients[0].age}`}
                                    </div>
                                    <div className="popup_write_letter_user_bottom">
                                        <div className="popup_write_letter_user_to_id">
                                            {`ID ${mailboxState.checkedRecipients[0].external_id}`}
                                        </div>
                                        <div className="popup_write_letter_user_place">
                                            {mailboxState.checkedRecipients[0].city_name
                                                ? mailboxState.checkedRecipients[0].city_name
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className={`popup_write_letter_users_hidden ${isActiveTo ? 'active' : ''}`}>
                                    {mailboxState.checkedRecipients.map((item, key) =>
                                        key > 0 ? (
                                            <div
                                                className={`popup_write_letter_user ${item.online ? 'online' : ''}`}
                                                key={item.external_id}
                                            >
                                                <div className="popup_write_letter_user_photo">
                                                    {userAvatar(item.image, item.name, '', Styles.user_avatar)}
                                                </div>
                                                <div className="popup_write_letter_user_info">
                                                    <div className="popup_write_letter_user_name">{`${item.name}, ${item.age}`}</div>
                                                    <div className="popup_write_letter_user_bottom">
                                                        <div className="popup_write_letter_user_to_id">{`ID ${item.external_id}`}</div>
                                                        <div className="popup_write_letter_user_place">
                                                            {item.city_name ? item.city_name : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div key={item.external_id} />
                                        ),
                                    )}
                                </div>
                            </div>

                            <div
                                className="c3l_letters_list_write_go_to_chat"
                                onClick={openComposeModal}
                                data-testid="add-more-btn"
                            >
                                <img src={IMAGES.c3l_add_more_men_btn} alt="" />
                                <span>Add more</span>
                            </div>
                        </div>
                    )}
                </div>
                <MessageForm
                    sender={sender}
                    checkedRecipientsID={mailboxState.checkedRecipients.map((item) => item.external_id)}
                    closeWriteLetterModal={closeWriteLetterModal}
                    openComposeModal={openComposeModal}
                    isCreateLetterForm
                />
                <div className="close_one_popup" onClick={closeWriteLetterModal} data-testid="close-btn">
                    <img src={IMAGES.popup_gift_close} alt="" />
                </div>
            </div>
        </div>
    );
};
