import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Man from './Man';

import * as mailboxAction from '../../../../store/actions/mailbox';

export default () => {
    const dispatch = useDispatch();
    const profilesState = useSelector((state) => state.profiles);
    const mailboxState = useSelector((state) => state.mailbox);
    const menuState = useSelector((state) => state.menu);

    useEffect(() => {
        dispatch(
            mailboxAction.getMaleList({
                personFilter: menuState.composeLetterFilter.length > 0 ? menuState.composeLetterFilter : '',
                userId: profilesState.active.external_id ? profilesState.active.external_id : '',
                search: menuState.composeLetterSearch.length > 0 ? menuState.composeLetterSearch : '',
            }),
        );
    }, [menuState.composeLetterFilter, menuState.composeLetterSearch]);

    return (
        <div className="popup_compose_men_list_wrap">
            <div className="popup_compose_men_list_wrap3">
                <div className={`popup_compose_men_list_wrap2 ${mailboxState?.maleList?.length ? '' : 'empty_list'}`}>
                    {mailboxState?.maleList?.length > 0 ? (
                        mailboxState?.maleList?.map((item) => <Man key={item.external_id} item={item} />)
                    ) : (
                        <div className="popup_compose_men_list_wrap2 empty_list_text">
                            You don&#39;t have any users that you can send a letter
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
