import React, { useEffect, useState } from 'react';
import * as menuAction from '../../../../store/actions/menu';
import { useDispatch, useSelector } from 'react-redux';

export default () => {
    const dispatch = useDispatch();
    const menuState = useSelector((state) => state.menu);
    const mailboxState = useSelector((state) => state.mailbox);

    const [manFilter, setManFilter] = useState('');
    const [manSearch, setManSearch] = useState('');
    const [isActiveFilter, setIsActiveFilter] = useState(false);

    const onToggleFilter = () => setIsActiveFilter(!isActiveFilter);

    const removeFilter = () => {
        dispatch(menuAction.setComposeLetterFilter(''));
    };

    const handleChangeFilterMan = (e) => {
        setManFilter(e.currentTarget.dataset.name);
        dispatch(menuAction.setComposeLetterFilter(e.currentTarget.dataset.name));
        onToggleFilter();
    };

    const handleChangeInputSearchMan = (e) => {
        setManSearch(e.currentTarget.value);
    };

    useEffect(() => {
        dispatch(menuAction.setComposeLetterSearch(manSearch));
    }, [manSearch, dispatch]);

    return (
        <div className="popup_compose_head">
            <div className="popup_compose_head_search">
                <input
                    type="text"
                    data-testid="idOrName"
                    placeholder="Search ID or name"
                    value={manSearch}
                    onChange={handleChangeInputSearchMan}
                />
                {manSearch.length > 0 ? (
                    <button onClick={() => setManSearch('')}>
                        <img src="../img/c2-remove.svg" alt="" />
                    </button>
                ) : (
                    <button>
                        <img src="../img/c2_search_btn.svg" alt="" />
                    </button>
                )}
            </div>
            <p>
                {mailboxState.maleList.length} / {mailboxState.malesCount}
            </p>
            <div className="popup_compose_head_right">
                <div className="popup_compose_head_choosen_filters">
                    {menuState.composeLetterFilter && (
                        <div
                            className={`popup_compose_head_choosen_filters_item ${isActiveFilter ? 'active' : ''}`}
                            onClick={removeFilter}
                        >
                            <span>{menuState.composeLetterFilter}</span>
                            <img src="../img/c3l-compose-remove-filter.svg" alt="" />
                        </div>
                    )}
                </div>
                <div className="popup_compose_head_choose_filter_wrap">
                    <div
                        className="popup_compose_head_choose_filter_main"
                        onClick={onToggleFilter}
                        data-testid="choose-filter-btn"
                    >
                        <img src="../img/c3l-choose-filter.svg" alt="" />
                        <span>Choose filter</span>
                    </div>
                    <div className={`popup_compose_head_choose_filter_list_wrap  ${isActiveFilter ? 'active' : ''}`}>
                        <div className="popup_compose_head_choose_filter_list">
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="online"
                                    checked={manFilter === 'online'}
                                    onChange={handleChangeFilterMan}
                                    id="pchcf_1"
                                />
                                <label htmlFor="pchcf_1">Online</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="dialogues"
                                    checked={manFilter === 'dialogues'}
                                    onChange={handleChangeFilterMan}
                                    id="pchcf_9"
                                />
                                <label htmlFor="pchcf_9">Dialogues</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="favorites"
                                    checked={manFilter === 'favorites'}
                                    onChange={handleChangeFilterMan}
                                    id="pchcf_2"
                                />
                                <label htmlFor="pchcf_2">Favorites</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="likes"
                                    checked={manFilter === 'likes'}
                                    onChange={handleChangeFilterMan}
                                    id="pchcf_3"
                                />
                                <label htmlFor="pchcf_3">Your likes</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="unanswered"
                                    checked={manFilter === 'unanswered'}
                                    onChange={handleChangeFilterMan}
                                    id="pchcf_4"
                                />
                                <label htmlFor="pchcf_4">Unread</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="maybe"
                                    checked={manFilter === 'maybe'}
                                    onChange={handleChangeFilterMan}
                                    id="pchcf_5"
                                />
                                <label htmlFor="pchcf_5">Maybe</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="special"
                                    checked={manFilter === 'special'}
                                    onChange={handleChangeFilterMan}
                                    id="pchcf_6"
                                />
                                <label htmlFor="pchcf_6">Special</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="looking"
                                    checked={manFilter === 'looking'}
                                    onChange={handleChangeFilterMan}
                                    id="pchcf_7"
                                />
                                <label htmlFor="pchcf_7">Looking at you</label>
                            </div>
                            {/* Someone may want to return it so commented for now */}
                            {/*<div className="popup_compose_head_choose_filter_row">*/}
                            {/*    <input*/}
                            {/*        type="radio"*/}
                            {/*        name="pchcf_1"*/}
                            {/*        data-name="connections"*/}
                            {/*        checked={manFilter === 'connections'}*/}
                            {/*        onChange={handleChangeFilterMan}*/}
                            {/*        id="pchcf_8"*/}
                            {/*    />*/}
                            {/*    <label htmlFor="pchcf_8">Connections</label>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
