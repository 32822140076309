import React, { useState } from 'react';
import styles from '../styles.module.scss';
import * as IMAGES from '../../common/Images';
import * as POSTS from '../../../constants/posts';
import moment from 'moment';
import { makeCDN } from '../../../services/methods';
import * as ATTACH from '../../../constants/attach';

export default ({ post, hidePost, showPost, openCreateModal, openViewModal }) => {
    const [currentImg, setCurrentImg] = useState(0);

    const nextImage = (e) => {
        e.stopPropagation();
        setCurrentImg((prev) => (prev === post?.files?.length - 1 ? 0 : prev + 1));
    };

    const prevImage = (e) => {
        e.stopPropagation();
        setCurrentImg((prev) => (prev === 0 ? post?.files?.length - 1 : prev - 1));
    };

    const postLines = post?.text?.split('\n') ?? [];
    const isEditType = POSTS.EDIT_POST_TYPE.includes(post?.status);
    const currentFile = post?.files?.[currentImg];

    const switchStatus = (statusID) => {
        switch (statusID) {
            case POSTS.STATUS_PROCESS:
                return 'In progress';
            case POSTS.STATUS_APPROVE:
                return 'Approved';
            case POSTS.STATUS_DECLINE:
                return 'Declined';
            case POSTS.STATUS_REWORK:
                return 'Rework';
            case POSTS.STATUS_DRAFT:
                return 'Draft';
            case POSTS.STATUS_HIDDEN:
                return 'Hidden';
            default:
                return '';
        }
    };

    const onOpenViewModal = () => {
        if (post?.status === POSTS.STATUS_DRAFT) {
            openCreateModal('edit', post);
        } else {
            openViewModal(post);
        }
    };

    return (
        <div className={styles.posts_page_grid_item}>
            <div className={styles.posts_page_grid_img_wrap} onClick={onOpenViewModal}>
                {post?.files?.length > 1 && (
                    <>
                        <span className={`${styles.posts_page_icon_arrow} ${styles.left}`} onClick={prevImage} />
                        <span className={`${styles.posts_page_icon_arrow} ${styles.right}`} onClick={nextImage} />
                    </>
                )}

                {currentFile?.type === ATTACH.TYPE_VIDEO && <span className={styles.icon_play} />}
                {!!post?.files?.length && <img src={makeCDN(currentFile?.imageSmallUrl, 'POSTS')} alt="" />}

                <div className={styles.posts_page_icon_dots_row}>
                    {post?.files?.length > 1 &&
                        post?.files.map((el, index) => (
                            <span
                                className={`${styles.posts_page_icon_dot} ${index === currentImg ? styles.active : ''}`}
                                key={index}
                            />
                        ))}
                </div>
            </div>
            <div className={styles.posts_page_grid_item_content}>
                <div className={styles.posts_page_grid_activity_row}>
                    <div className={styles.posts_page_grid_activity_item_wrap}>
                        <div className={styles.posts_page_grid_activity_item}>
                            <img src={IMAGES.post_like} alt="" />
                            {post?.countLikes ?? ''}
                        </div>
                    </div>

                    {isEditType ? (
                        <div
                            className={`${styles.posts_page_grid_activity_item} ${styles.action}`}
                            onClick={() => openCreateModal('edit', post)}
                        >
                            <img src={IMAGES.post_edit} alt="" />
                            Edit post
                        </div>
                    ) : (
                        post?.status === POSTS.STATUS_APPROVE && (
                            <div
                                className={`${styles.posts_page_grid_activity_item} ${styles.action}`}
                                onClick={() => hidePost(post)}
                            >
                                <img src={IMAGES.trash_bin_icon} alt="" />
                                Hide post
                            </div>
                        )
                    )}

                    {post?.status === POSTS.STATUS_HIDDEN && (
                        <div
                            className={`${styles.posts_page_grid_activity_item} ${styles.action}`}
                            onClick={() => showPost(post)}
                        >
                            <img src={IMAGES.show_icon} alt="" />
                            Show post
                        </div>
                    )}
                </div>

                <p className={styles.posts_page_grid_item_text}>
                    {!!postLines?.length &&
                        postLines.map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                </p>

                <button className={styles.posts_page_grid_item_see_more} onClick={onOpenViewModal}>
                    See more
                </button>

                <div className={styles.posts_page_grid_item_bottom_row}>
                    <div className={`${styles.posts_page_grid_item_status} ${styles[`status_${post?.status}`]}`}>
                        {switchStatus(post?.status)}
                    </div>
                    <div className={styles.posts_page_grid_item_data_wrap}>
                        <span>{moment(post?.createdAt).format('DD.MM.YYYY')}</span>
                        <span>{moment(post?.createdAt).format('HH:mm')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
